import { toAbsoluteUrl } from "../../_metronic/helpers";
import { generateLast50Years } from "./helpers";

export interface IOptionsMap {
  [x: string]: { value: string; label: string };
}
export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  USER: "USER",
  ADMIN: "ADMIN",
};

export const STATUS_OPTION = [
  { value: 1, label: "Active" },
  { value: 0, label: "Inactive" },
];

export const STATUS_HASH_MAP = {
  1: { value: "Active", label: "Active" },
  0: { value: "Inactive", label: "Inactive" },
};

export const STATES_OPTIONS_MAP: IOptionsMap = {
  "Andaman And Nicobar Islands": {
    value: "Andaman And Nicobar Islands",
    label: "Andaman And Nicobar Islands",
  },
  "Andhra Pradesh": { value: "Andhra Pradesh", label: "Andhra Pradesh" },
  "Arunachal Pradesh": { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
  Assam: { value: "Assam", label: "Assam" },
  Bihar: { value: "Bihar", label: "Bihar" },
  Chandigarh: { value: "Chandigarh", label: "Chandigarh" },
  Chhattisgarh: { value: "Chhattisgarh", label: "Chhattisgarh" },
  "Dadra And Nagar Haveli": { value: "Dadra And Nagar Haveli", label: "Dadra And Nagar Haveli" },
  "Daman And Diu": { value: "Daman And Diu", label: "Daman And Diu" },
  Delhi: { value: "Delhi", label: "Delhi" },
  Goa: { value: "Goa", label: "Goa" },
  Gujarat: { value: "Gujarat", label: "Gujarat" },
  Haryana: { value: "Haryana", label: "Haryana" },
  "Himachal Pradesh": { value: "Himachal Pradesh", label: "Himachal Pradesh" },
  "Jammu And Kashmir": { value: "Jammu And Kashmir", label: "Jammu And Kashmir" },
  Jharkhand: { value: "Jharkhand", label: "Jharkhand" },
  Karnataka: { value: "Karnataka", label: "Karnataka" },
  Kerala: { value: "Kerala", label: "Kerala" },
  Lakshwadeep: { value: "Lakshwadeep", label: "Lakshwadeep" },
  "Madhya Pradesh": { value: "Madhya Pradesh", label: "Madhya Pradesh" },
  Maharashtra: { value: "Maharashtra", label: "Maharashtra" },
  Manipur: { value: "Manipur", label: "Manipur" },
  Meghlaya: { value: "Meghlaya", label: "Meghlaya" },
  Mizoram: { value: "Mizoram", label: "Mizoram" },
  Nagaland: { value: "Nagaland", label: "Nagaland" },
  Odisha: { value: "Odisha", label: "Odisha" },
  Puducherry: { value: "Puducherry", label: "Puducherry" },
  Punjab: { value: "Punjab", label: "Punjab" },
  Rajasthan: { value: "Rajasthan", label: "Rajasthan" },
  Sikkim: { value: "Sikkim", label: "Sikkim" },
  "Tamil Nadu": { value: "Tamil Nadu", label: "Tamil Nadu" },
  Telangana: { value: "Telangana", label: "Telangana" },
  Tripura: { value: "Tripura", label: "Tripura" },
  "Uttar Pradesh": { value: "Uttar Pradesh", label: "Uttar Pradesh" },
  Uttarakhand: { value: "Uttarakhand", label: "Uttarakhand" },
  "West Bengal": { value: "West Bengal", label: "West Bengal" },
};

export const STATES_OPTIONS = Object.values(STATES_OPTIONS_MAP);

export const GENDER_OPTIONS_MAP: IOptionsMap = {
  m: { value: "m", label: "Male" },
  f: { value: "f", label: "Female" },
  o: { value: "o", label: "Other" },
};
export const GENDER_OPTIONS = [
  { value: "m", label: "Male" },
  { value: "f", label: "Female" },
  { value: "o", label: "Other" },
];

export const BLANK_USER_IMAGE = toAbsoluteUrl("media/avatars/blank.png");
export const RUPEE = "₹";
export const SUBSCRIPTION_MODE: any = {
  every_day: "Every day",
  "Buy Once": "Buy once",
  week_day: "Week day",
};

export const TRANSACTION_TYPE_OPTIONS = [
  { value: "Debit", label: "Debit" },
  { value: "Credit", label: "Credit" },
];

export const VISIBLE_ON_OPTIONS = [
  {
    label: "Internal & External",
    value: "Internal & External",
  },
  {
    label: "Internal",
    value: "Internal",
  },
];

export const VISIBLE_ON_OPTIONS_MAP: { [x: string]: { value: string; label: string } } = {
  "Internal & External": {
    label: "Internal & External",
    value: "Internal & External",
  },
  Internal: {
    label: "Internal",
    value: "Internal",
  },
};

export const ORDER_STATUS = {
  0: "Undelivered",
  1: "Delivered",
  2: "Cancelled",
  3: "All",
};

export const ORDER_STATUS_OPTIONS = {
  0: { value: 0, label: "Undelivered" },
  1: { value: 1, label: "Delivered" },
  2: { value: 2, label: "Cancelled" },
  3: { value: 3, label: "All" },
};

export const ORDER_STATUS_BADGE_CLASSNAME = {
  0: "bg-warning text-white",
  1: "bg-success text-white",
  2: "bg-danger text-white",
};

export const COLOR = [
  "#FF5733", // Red-Orange
  "#33FF57", // Green
  "#3357FF", // Blue
  "#FF33A1", // Pink
  "#FFFF33", // Yellow
  "#33FFF6", // Cyan
  "#F633FF", // Magenta
  "#FF9633", // Orange
  "#33FF96", // Light Green
  "#9633FF", // Purple
  "#FF3333", // Red
  "#33FF33", // Lime
  "#3333FF", // Dark Blue
  "#FF33D4", // Deep Pink
  "#FFD433", // Gold
  "#33FFD4", // Turquoise
  "#D433FF", // Violet
  "#FFB833", // Amber
  "#33FFB8", // Aqua
  "#B833FF", // Lavender
];

export const MONTH_OPTIONS = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const YEAR_OPTIONS = generateLast50Years().map((v) => ({ label: v, value: v }));

export const EVENT_TYPE = {
  REVERSE_AUCTION: "Reverse Auction",
  FORWARD_AUCTION: "Forward Auction",
  TENDER: "Tender",
  EQUIPMENT_LISTING: "Equipment Listing",
};

export const EVENT_ACTION = {
  APPROVE: "approve",
  REJECT: "reject",
  SENT_FOR_PUBLISH: "sent_for_publish",
  REJECT_PUBLISH: "reject_publish",
  PUBLISH: "publish",
};

export const YES_OR_NO = {
  YES: "Yes",
  NO: "No",
};
