/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, lazy, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { App } from "../App";
const Logout = lazy(() => import("../modules/auth/views/Logout"));
const AuthRoutes = lazy(() => import("./AuthRoutes"));
const PendingApproval = lazy(() => import("../pages/pendingApproval/PendingApproval"));
const AuctionMode = lazy(() => import("../pages/auctionMode/AuctionMode"));
const PreviewDocument = lazy(() => import("../pages/previewDocument/views/PreviewDocument"));
import useAuth from "../modules/auth/hooks/useAuth";
import { Modules } from "../pages/Modules/models/Modules.interfaces";
import { useDynamicRoutes } from "./DynamicRouteProvider";
import { MenuComponent } from "../../_metronic/assets/ts/components";
import { APP_ROUTES, PUBLIC_ROUTES } from "./routes";
import ForceChangePassword from "../pages/forceChangePassword/ForceChangePassword";
import { AuthLayout } from "../modules/auth/AuthLayout";
import LoadingPage from "../pages/loadingPage/LoadingPage";
import useViteErrorHandling from "../hooks/useViteErrorHandling";
// import PendingApproval from "../pages/pendingApproval/PendingApproval";
// import AuctionMode from "../pages/auctionMode/AuctionMode";
// import PreviewDocument from "../pages/previewDocument/views/PreviewDocument";
// import useBrowserTabEvent, { BROADCAST_EVENT } from '../hooks/useBrowserTabEvent'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { BASE_URL } = import.meta.env;

const AppRoutes: FC = () => {
  const { isAuthenticated, currentRole, currentUser, currentMode } = useAuth();
  const { dynamicRoutes, homeRoute, isLoading } = useDynamicRoutes();

  useViteErrorHandling();

  useEffect(() => {
    // setTimeout(() => {
      if (!isLoading) {
        MenuComponent.bootstrap();
        document.body.classList.remove("page-loading");
      }
    // }, 600);
  }, [isLoading, currentMode]);

  // useBrowserTabEvent({
  //   [BROADCAST_EVENT.LOGOUT_EVENT]: {
  //     eventName: BROADCAST_EVENT.LOGOUT_EVENT,
  //     callback: (data: { redirectUrl: string }) => {
  //       const fullPath = window.location.pathname + window.location.search;
  //       window.location.href = data?.redirectUrl ? `${APP_ROUTES.LOGOUT}?redirectUrl=${fullPath}` : APP_ROUTES.LOGOUT
  //     }
  //   },
  //   [BROADCAST_EVENT.LOGIN_EVENT]: {
  //     eventName: BROADCAST_EVENT.LOGIN_EVENT,
  //     callback: () => window.location.reload()
  //   }
  // })

  return (
    <BrowserRouter basename={BASE_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path={APP_ROUTES.LOGOUT} element={<Logout />} />
          {isAuthenticated() ? (
            currentUser?.isApproved ? (
              <Route path="/*" element={<PendingApproval />} />
            ) : !currentUser?.mode_type ? (
              <Route path="/*" element={<AuctionMode />} />
            ) : isLoading ? (
              <Route path="/*" element={<LoadingPage />} />
            ) : (
              <>
                <Route element={<AuthLayout />}>
                  <Route path={APP_ROUTES.ForceChangePassword} element={<ForceChangePassword />} />
                </Route>
                <Route
                  path="/*"
                  element={
                    <PrivateRoutes
                      allRoutes={dynamicRoutes as Modules[]}
                      homeRoute={homeRoute}
                      isLoading={isLoading}
                      currentRole={currentRole as string}
                    />
                  }
                />

                <Route path="error/*" element={<ErrorsPage />} />
              </>
            )
          ) : (
            <>
              <Route path="auth/*" element={<AuthRoutes />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
        <Route path={PUBLIC_ROUTES.PREVIEW_DOCUMENT} element={<PreviewDocument />} />
      </Routes>
    </BrowserRouter>
  );
};

// const AppRoute = () => {
//   const currentUser = useSelector(selectCurrentUser)

//   const router = createBrowserRouter([
//     {
//       path: "/",
//       element: <App />,
//       errorElement: <Error404 />,
//       children: currentUser ?
//         [
//           {
//             path: '/*',
//             children: getRoutes(privateRoutes, currentRole)
//           },
//           {
//             element: <Navigate to={APP_ROUTES.DASHBOARD} />,
//             index: true
//           },

//         ]
//         : [{
//           path: 'auth/*',
//           children: getRoutes(authRoutes, currentRole)
//         },
//         {
//           path: '*',
//           element: <Navigate to={'/auth'} />,
//         }]
//     },
//   ], { basename: BASE_URL });

//   return <RouterProvider router={router} />
// }
export { AppRoutes };
