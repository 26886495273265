import { createContext, useContext, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
// import { RightToolbar } from "../partials/layout/RightToolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
// import { ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan } from "../partials";
import { MenuComponent } from "../assets/ts/components";
import { useGetProfileCompletionQuery } from "../../app/app.api";
const ProfileCompletionContext = createContext({
  basic_score: 0,
  business_score: 0,
  company_code: 0,
  contact_score: 0,
  documentation_score: 0,
  last_updated_at: "",
  profile_completion_percentage: 0,
  isLoading: true,
});
const MasterLayout = () => {
  const location = useLocation();
  const { data, isFetching, isLoading } = useGetProfileCompletionQuery();
  const values = data?.data || {};
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 500);
  }, [location.key]);

  return (
    <ProfileCompletionContext.Provider value={{ ...values, isLoading: isFetching || isLoading }}>
      <PageDataProvider>
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderWrapper />

            <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer /> */}
        {/* <RightToolbar /> */}
        {/* <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <InviteUsers /> */}
        {/* <UpgradePlan /> */}
        {/* end:: Modals */}
        <ScrollTop />
      </PageDataProvider>
    </ProfileCompletionContext.Provider>
  );
};

export { MasterLayout };

export const useProfileCompletion = () => useContext(ProfileCompletionContext);
