import { useEffect } from "react";
import AlertBoxModal from "./AlertBoxModal";
import { useSelector } from "react-redux";
import { selectUploadFileProgress, setProgress } from "../../app.slice";
import { useModal } from "../../hooks/useModal";
import { useDispatch } from "react-redux";
import CustomModal from "../CustomModal/CustomModal";
import AlertBoxLayout from "../AlertBoxLayout/AlertBoxLayout";
export interface IUploadFileBox {
  title?: string;
  message?: string;
  uploadProgress?: 0;
}
const UploadFileBox = ({
  title = "Uploading Files",
  message = "Please wait while we upload your files...",
  ...props
}: IUploadFileBox) => {
  const uploadFileState = useSelector(selectUploadFileProgress);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setProgress({ progress: 0, status: "fulfilled" }));
  };

  return (
    <AlertBoxLayout
      open={uploadFileState?.status === "pending"}
      onClose={handleClose}
      title={uploadFileState?.title ?? title}
      noFooter
      centered
      type="primary"
      icon={{ name: "arrow-up", type: "duotone", color: "info" }}
      msg=""
      renderMsg={() => (
        <div>
          <h4>{uploadFileState?.message ?? message}</h4>
          <div className="h-5px mx-3 w-100 bg-light mb-3">
            <div
              className={`bg-primary rounded h-10px`}
              role="progressbar"
              style={{ width: `${uploadFileState?.progress ?? props?.uploadProgress}%` }}
              aria-valuenow={uploadFileState?.progress ?? props?.uploadProgress}
              aria-valuemin={0}
              aria-valuemax={100}
            />
            <span className="float-right">{uploadFileState?.progress ?? props?.uploadProgress}%..</span>
          </div>
        </div>
      )}
    />
  );
};

export default UploadFileBox;
