import "../sentry.ts";
import { createRoot } from "react-dom/client";
// Axios
// import axios from 'axios'
// import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/sass/style.react.scss";
import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import { AppRoutes } from "./app/routing/AppRoutes";
import { Provider } from "react-redux";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { persistor, store } from "./store/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PersistGate } from "redux-persist/integration/react";
import { MetronicSplashScreenProvider } from "./_metronic/layout/core";
import { DynamicRouteProvider } from "./app/routing/DynamicRouteProvider";
import SignalRProvider from "./app/services/signalR/SignalRProvider";
import BrowserEventProvider, {
  skippedPathFromRedirect,
} from "./app/components/BrowserEventProvider/BrowserEventProvider.tsx";
import { BROADCAST_EVENT } from "./app/hooks/useBrowserTabEvent.ts";
import { APP_ROUTES } from "./app/routing/routes.tsx";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FirebaseProvider } from "./app/components/FirebaseProvider/FirebaseProvider.tsx";
import { captureException, ErrorBoundary } from "@sentry/react";
import Error500 from "./app/modules/errors/components/Error500.tsx";

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// Chart.register(...registerables)

const queryClient = new QueryClient();
const container = document.getElementById("root");

const broadcastEvents = {
  [BROADCAST_EVENT.LOGOUT_EVENT]: {
    eventName: BROADCAST_EVENT.LOGOUT_EVENT,
    callback: (data: { redirectUrl: string }) => {
      const { pathname, search } = window.location;
      if (!skippedPathFromRedirect.some((v) => pathname.includes(v)) && pathname !== "/") {
        const fullPath = `${pathname}${search}`;
        window.location.href = data?.redirectUrl
          ? `${APP_ROUTES.LOGOUT}?redirectUrl=${fullPath}`
          : APP_ROUTES.LOGOUT;
      }
    },
  },
  [BROADCAST_EVENT.LOGIN_EVENT]: {
    eventName: BROADCAST_EVENT.LOGIN_EVENT,
    callback: () => window.location.reload(),
  },
};

if (container) {
  createRoot(container).render(
    <ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        const errorId = captureException(error);
        return (
          <div className="d-flex flex-column flex-root">
            <div className="d-flex flex-column flex-center flex-column-fluid">
              <div className="d-flex flex-column flex-center p-10">
                <div className="card card-flush  w-lg-650px ">
                  <div className="card-body">
                    <Error500 errorId={errorId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      {/* <PersistGate persistor={persistor}> */}
      <Provider store={store}>
        <FirebaseProvider>
          <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
              <GoogleReCaptchaProvider
                reCaptchaKey={import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_KEY}
                // language="[optional_language]"
                // useRecaptchaNet="[optional_boolean_value]"
                // useEnterprise="[optional_boolean_value]"
                // scriptProps={{
                //   async: false, // optional, default to false,
                //   defer: false, // optional, default to false
                //   appendTo: 'head', // optional, default to "head", can be "head" or "body",
                //   nonce: undefined // optional, default undefined
                // }}
                container={{
                  // optional to render inside custom element
                  element: "recaptcha",
                  parameters: {
                    badge: "bottomleft", // optional, default undefined
                    // theme: 'dark', // optional, default undefined
                  },
                }}
              >
                <MetronicSplashScreenProvider>
                  <DynamicRouteProvider>
                    {/* <AuthProvider> */}
                    <SignalRProvider>
                      <BrowserEventProvider events={broadcastEvents}>
                        <AppRoutes />
                      </BrowserEventProvider>
                    </SignalRProvider>
                    {/* <AppRoute /> */}
                    {/* </AuthProvider> */}
                  </DynamicRouteProvider>
                </MetronicSplashScreenProvider>
              </GoogleReCaptchaProvider>
            </MetronicI18nProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </FirebaseProvider>
      </Provider>
      {/* </PersistGate> */}
    </ErrorBoundary>
  );
}
