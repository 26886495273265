import { lazy } from "react";

const Error404 = lazy(() => import("../modules/errors/components/Error404"));
const Error500 = lazy(() => import("../modules/errors/components/Error500"));
const Error401 = lazy(() => import("../modules/errors/components/Error401"));
const DefaultPage = lazy(() => import("../pages/defaultPage/views/DefaultPage"));
const BuilderPageWrapper = lazy(() => import("../pages/layout-builder/BuilderPageWrapper"));
const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
const Login = lazy(() => import("../modules/auth/views/LoginPage"));
const Projects = lazy(() => import("../modules/profile/components/Projects"));
const Overview = lazy(() => import("../modules/profile/components/Overview"));
const Logout = lazy(() => import("../modules/auth/views/Logout"));
const ForgotPassword = lazy(() => import("../modules/auth/views/ForgotPassword"));
const Registration = lazy(() => import("../modules/auth/views/Registration"));
const ListModules = lazy(() => import("../pages/moduleCreator/views/ListModules"));
const Modules = lazy(() => import("../pages/Modules/views/Modules"));
const Roles = lazy(() => import("../pages/roles/views/Roles"));
const LoginWIthPhone = lazy(() => import("../modules/auth/views/LoginWIthPhone"));
const LoadingPage = lazy(() => import("../pages/loadingPage/LoadingPage"));
const AddAdminDashboard = lazy(() => import("../pages/adminDashboard/views/AddAdminDashboard"));
// conduct routes
const AdminDashboard = lazy(() => import("../pages/adminDashboard/views/AdminDashboard"));
const Users = lazy(() => import("../pages/users/views/Users"));
const AddDepartment = lazy(() => import("../pages/department/views/AddDepartment"));
const Department = lazy(() => import("../pages/department/views/Department"));
const AddUnit = lazy(() => import("../pages/unit/views/AddUnit"));
const Unit = lazy(() => import("../pages/unit/views/Unit"));
const Suppliers = lazy(() => import("../pages/suppliers/views/Suppliers"));
const TenderNew = lazy(() => import("../pages/tender/views/TenderNew"));
const TenderDraft = lazy(() => import("../pages/tender/views/TenderDraft"));
const TenderApprove = lazy(() => import("../pages/tender/views/TenderApprove"));
const TenderPublish = lazy(() => import("../pages/tender/views/TenderPublish"));
const ReverseAuctionNew = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionNew"));
const ReverseAuctionTabs = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionTabs"));
const ReverseAuctionTenderList = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionTenderList"));
const ReverseAuctionDraft = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionDraft"));
const ReverseAuctionApprove = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionApprove"));
const ReverseAuctionPublish = lazy(() => import("../pages/reverseAuction/views/ReverseAuctionPublish"));
const ConductLiveReverseAuction = lazy(
  () => import("../pages/conductLiveReverseAuction/views/ConductLiveReverseAuction")
);
const ForwardAuctionNew = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionNew"));
const ForwardAuctionDraft = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionDraft"));
const ForwardAuctionApprove = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionApprove"));
const ForwardAuctionPublish = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionPublish"));

const UpcomingForwardAuction = lazy(
  () => import("../pages/upcomingForwardAuction/views/UpcomingForwardAuction")
);
const UpcomingReverseAuction = lazy(
  () => import("../pages/upcomingReverseAuction/views/UpcomingReverseAuction")
);
const UpcomingTender = lazy(() => import("../pages/upcomingTender/views/UpcomingTender"));

const ConductLiveForwardAuction = lazy(
  () => import("../pages/conductLiveForwardAuction/views/ConductLiveForwardAuction")
);
const ConductLiveTender = lazy(() => import("../pages/conductLiveTender/views/ConductLiveTender"));
const ForwardAuctionTabs = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionTabs"));
const ForwardAuctionTenderList = lazy(() => import("../pages/forwardAuction/views/ForwardAuctionTenderList"));
const EquipmentListingNew = lazy(() => import("../pages/equipmentListing/views/EquipmentListingNew"));
const EquipmentListingDraft = lazy(() => import("../pages/equipmentListing/views/EquipmentListingDraft"));
const EquipmentListingApprove = lazy(() => import("../pages/equipmentListing/views/EquipmentListingApprove"));
const EquipmentListingPublish = lazy(() => import("../pages/equipmentListing/views/EquipmentListingPublish"));
const MyProfile = lazy(() => import("../pages/myProfile/views/MyProfile"));
const ActivityLogs = lazy(() => import("../pages/activityLogs/views/ActivityLogs"));
const Help = lazy(() => import("../pages/Help/views/Help"));
// Reports
const TenderReports = lazy(() => import("../pages/reports/views/tenderReports/TenderReports"));
const EquipmentListingReports = lazy(
  () => import("../pages/reports/views/equipmentListingReports/EquipmentListingReports")
);
const ForwardAuctionReports = lazy(
  () => import("../pages/reports/views/forwardAuctionReports/ForwardAuctionReports")
);
const ReverseAuctionReports = lazy(
  () => import("../pages/reports/views/reverseAuctionReports/ReverseAuctionReports")
);
const EvaluationReports = lazy(() => import("../pages/reports/views/evaluationReports/EvaluationReports"));
const ForwardStatisticsProductCategoryWiseReports = lazy(
  () =>
    import(
      "../pages/reports/views/forwardStatisticsProductCategoryWise/ForwardStatisticsProductCategoryWiseReports"
    )
);
const ReverseStatisticsProductCategoryWiseReports = lazy(
  () =>
    import(
      "../pages/reports/views/reverseStatisticsProductCategoryWise/ReverseStatisticsProductCategoryWiseReports"
    )
);
const EventNamingConvention = lazy(() => import("../pages/configuration/views/EventNamingConvention"));
const Profile = lazy(() => import("../pages/configuration/views/Profile"));

// participant routes
const ParticipateDashboard = lazy(() => import("../pages/participateDashboard/views/ParticipateDashboard"));
const LiveTender = lazy(() => import("../pages/participateLiveTender/views/LiveTender"));
const AddLiveTender = lazy(() => import("../pages/participateLiveTender/views/AddLiveTender"));
const LiveReverseAuction = lazy(
  () => import("../pages/participateLiveReverseAuction/views/LiveReverseAuction")
);
const LiveForwardAuction = lazy(
  () => import("../pages/participateLiveForwardAuction/views/LiveForwardAuction")
);

const AddTermsAndCondition = lazy(() => import("../pages/termsAndCondition/views/AddTermsAndCondition"));
const TermsAndCondition = lazy(() => import("../pages/termsAndCondition/views/TermsAndCondition"));
const AddProduct = lazy(() => import("../pages/product/views/AddProduct"));
const Product = lazy(() => import("../pages/product/views/Product"));
const AddCompany = lazy(() => import("../pages/company/views/AddCompany"));
const Company = lazy(() => import("../pages/company/views/Company"));
//imports

export const routeComponentMap: { [x: string]: unknown } = {
  Login,
  Registration,
  ForgotPassword,

  Projects,
  Overview,
  Error401,
  Error404,
  Error500,
  ProfilePage,
  BuilderPageWrapper,
  Logout,
  ListModules,
  Modules,
  Users,
  Roles,
  LoginWIthPhone,
  LoadingPage,
  AddAdminDashboard,
  // conduct routes
  AdminDashboard,
  AddDepartment,
  Department,
  Suppliers,
  TenderNew,
  TenderDraft,
  TenderApprove,
  TenderPublish,
  ReverseAuctionNew,
  ReverseAuctionTabs,
  ReverseAuctionTenderList,
  ReverseAuctionDraft,
  ReverseAuctionApprove,
  ReverseAuctionPublish,
  DefaultPage,
  ConductLiveTender,
  ConductLiveReverseAuction,
  ConductLiveForwardAuction,
  ForwardAuctionNew,
  ForwardAuctionDraft,
  ForwardAuctionApprove,
  ForwardAuctionPublish,
  ForwardAuctionTabs,
  ForwardAuctionTenderList,
  EquipmentListingNew,
  EquipmentListingDraft,
  EquipmentListingPublish,
  EquipmentListingApprove,
  TenderReports,
  EquipmentListingReports,
  ForwardAuctionReports,
  ReverseAuctionReports,
  EvaluationReports,
  ForwardStatisticsProductCategoryWiseReports,
  ReverseStatisticsProductCategoryWiseReports,
  EventNamingConvention,
  Profile,
  AddTermsAndCondition,
  TermsAndCondition,
  AddUnit,
  Unit,
  MyProfile,
  AddCompany,
  Company,
  UpcomingForwardAuction,
  UpcomingReverseAuction,
  UpcomingTender,
  ActivityLogs,
  Help,
  // participant routes

  ParticipateDashboard,
  LiveTender,
  AddLiveTender,
  LiveReverseAuction,
  LiveForwardAuction,

  AddProduct,
  Product,
  //exports
};
