/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRolesAndPersmissions, LoginRequest } from "../models/Auth.interface";
import { USER_API } from "../apis";
import { api } from "../../../services/api/api";
import { IModeType, User } from "../../../pages/users/models/Users.interfaces";
import { IResponse } from "../../../network/network.interfaces";
import { appNotification } from "../../../app.slice";
import { BackendAuthApi, USER_MANAGEMENT_BASE_URL } from "../../../services/api/api.constant";
import { IResetPasswordForm } from "../components/forgotPasswordStep/ResetPasswordForm";

export interface UserResponse {
  status: boolean;
  data: User;
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    
    getProfile: builder.query<UserResponse, { user_id: string }>({
      query: ({ user_id }) => ({
        url: `${import.meta.env.VITE_APP_PROD_API_URL}${USER_API}/${user_id}`,
      }),
      providesTags: (result) => [{ type: "Auth", id: result?.data?.token }],
    }),
    login: builder.mutation<IResponse<any>, LoginRequest>({
      query: (credentials) => ({
        url: `${BackendAuthApi}/loginWithUserIDPassword`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: (result) => [
        { type: "Auth", id: result?.data?.token },
        // { type: "Modules", id: "PERMISSIONS" },
      ],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status)
            dispatch(
              appNotification({
                type: "success",
                title: "Login successful",
                msg: data?.message || "Login successful",
                notificationType: "alert",
              })
            );
          else
            dispatch(
              appNotification({
                type: "danger",
                title: "Login Failed",
                msg: data?.message || "Error while login",
              })
            );
        } catch (err) {
          dispatch(
            appNotification({
              type: "danger",
              title: "Login Failed",
              msg: JSON.stringify(err) || "Error while login",
            })
          );
        }
      },
    }),
    loginWithPhoneNumber: builder.mutation<
      IResponse<string>,
      { mobile_number: string; reCaptchaToken: string }
    >({
      query: (credentials) => ({
        url: `${BackendAuthApi}/generateOTPForLogin`,
        method: "POST",
        body: credentials,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status)
            dispatch(
              appNotification({
                type: "success",
                title: "Login successful",
                msg: data?.message || "Login successful",
                notificationType: "alert",
              })
            );
          else
            dispatch(
              appNotification({
                type: "danger",
                title: "Login Failed",
                msg: data?.message || "Error while login",
              })
            );
        } catch (err) {
          dispatch(
            appNotification({
              type: "danger",
              title: "Login Failed",
              msg: JSON.stringify(err) || "Error while login",
            })
          );
        }
      },
    }),
    validateOtp: builder.mutation<
      IResponse<User>,
      { mobile_number: string; otp: number; reCaptchaToken: string }
    >({
      query: (credentials) => ({
        url: `${BackendAuthApi}/verifyOTPForLogin`,
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status)
            dispatch(
              appNotification({
                type: "success",
                title: "Login successful",
                msg: data?.message || "Login successful",
                notificationType: "alert",
              })
            );
          else
            dispatch(
              appNotification({
                type: "danger",
                title: "Login Failed",
                msg: data?.message || "Error while login",
              })
            );
        } catch (err) {
          dispatch(
            appNotification({
              type: "danger",
              title: "Login Failed",
              msg: JSON.stringify(err) || "Error while login",
            })
          );
        }
      },
      invalidatesTags: (result) => [
        { type: "Auth", id: result?.data?.token },
        // { type: "Modules", id: "PERMISSIONS" },
      ],
    }),
    sendforgotPasswordOtp: builder.mutation<IResponse<any>, { user_id: string }>({
      query: (payload) => ({
        url: `${BackendAuthApi}/generateOTPForPasswordReset`,
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!data?.status)
            dispatch(
              appNotification({
                type: "danger",
                title: "Sending Otp Failed",
                notificationType: "toast",
                msg: data?.message || "Error while Generating Otp. please try later",
              })
            );
        } catch (err) {
          dispatch(
            appNotification({
              type: "danger",
              title: "Sending Otp Failed",
              notificationType: "toast",
              msg: JSON.stringify(err) || "Error while Generating Otp. please try later",
            })
          );
        }
      },
    }),
    resetPassword: builder.mutation<IResponse<any>, IResetPasswordForm>({
      query: (payload) => ({
        url: `${BackendAuthApi}/verifyOTPForPasswordReset`,
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status)
            dispatch(
              appNotification({
                type: "success",
                title: "Reset Successfully",
                msg: data?.message || "Password has been reset successfully",
              })
            );
          else
            dispatch(
              appNotification({
                type: "danger",
                title: "Reset password Failed",
                msg: data?.message || "Error while reseting password",
              })
            );
        } catch (err) {
          dispatch(
            appNotification({
              type: "danger",
              title: "Reset password Failed",
              msg: JSON.stringify(err) || "Error while reseting password",
            })
          );
        }
      },
    }),

    updateProfile: builder.mutation<IResponse<any>, any>({
      query: (payload) => {
        const formData = payload;
        //    uncoment below for  multipart formdata
        // formData = new FormData();
        // Object.keys(payload).map(f => formData.append(f, payload?.[f]))
        return {
          method: "PUT",
          url: `${USER_API}/${payload.user_id}`,
          body: formData,
          // formdata: true, uncomment for multipart formdata
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.status)
            dispatch(
              appNotification({
                title: "Saved",
                type: "success",
                msg: data?.message || "Saved successfully",
                notificationType: "modal",
              })
            );
          else dispatch(appNotification({ title: "Error", msg: data?.message || "Error while Saving!" }));
        } catch (err) {
          dispatch(appNotification({ title: "Error", msg: "Error while Saving!" }));
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: "Auth", id: arg.token }],
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
});

export const {
  useLoginMutation,
  useProtectedMutation,
  useLoginWithPhoneNumberMutation,
  useValidateOtpMutation,
  useUpdateProfileMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useResetPasswordMutation,
  useSendforgotPasswordOtpMutation,
} = authApi;
