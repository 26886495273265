import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { APP_ROUTES, PUBLIC_ROUTES, getRoutesNew } from "./routes";
import { Modules } from "../pages/Modules/models/Modules.interfaces";
import useAuth from "../modules/auth/hooks/useAuth";
import { getDecodedUrl, setEncodedUrl } from "../utils/session";
import { useEffect } from "react";
import { useLazyGetheartBeatQuery } from "../app.api";

const PrivateRoutes = ({
  allRoutes,
  currentRole,
  isLoading,
  homeRoute,
}: {
  allRoutes: Modules[];
  currentRole: string;
  isLoading: boolean;
  homeRoute: string;
}) => {
  const allRoutesComponent =
    allRoutes && currentRole
      ? (getRoutesNew([...allRoutes] as Modules[], currentRole as string) as JSX.Element[])
      : [];
  const { isForceChangePassword, currentUser } = useAuth();
  const redirectUrl = getDecodedUrl();
  const [getHeartBeat] = useLazyGetheartBeatQuery({ pollingInterval: 1000 });

  useEffect(() => {
    if (!isLoading && redirectUrl && redirectUrl !== "") {
      setEncodedUrl("");
    }
  }, [isLoading, redirectUrl]);

  useEffect(() => {
    if (currentUser?.user_id) {
      getHeartBeat();
    }
  }, [currentUser?.user_id]);
  return (
    <Routes>
      {isForceChangePassword ? (
        <Route
          key={APP_ROUTES.ForceChangePassword}
          path="*"
          element={<Navigate to={`${APP_ROUTES.ForceChangePassword}`} />}
        />
      ) : (
        <Route key={"masterlayout"} element={<MasterLayout />}>
          <Route
            key={"dashboard-redirect"}
            path="auth/*"
            element={<Navigate to={`${redirectUrl && redirectUrl !== "" ? redirectUrl : homeRoute}`} />}
          />
          {allRoutesComponent}
          <Route
            index
            element={<Navigate to={`${redirectUrl && redirectUrl !== "" ? redirectUrl : homeRoute}`} />}
          />
          <Route path="*" element={<Navigate to={PUBLIC_ROUTES.ERROR_404} />} />
        </Route>
      )}
    </Routes>
  );
};

export { PrivateRoutes };
