import "./authLayout.css";
import { useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { KTCard, toAbsoluteUrl } from "../../../_metronic/helpers";
// import { StyledBackground } from './components/StyledBackground'
import useAuth from "./hooks/useAuth";
import { useDispatch } from "react-redux";
import { logoutUser } from "./features/auth.slice";

const AuthLayout = () => {
  const { currentUser } = useAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser?.user_id) {
      dispatch(logoutUser());
    }
  }, [currentUser, dispatch]);

  return (
    // <StyledBackground>
    <div className="min-vh-100 bg-gradient-custom d-flex align-items-center justify-content-center p-3">
      {/* begin::Body */}
      {/* <div className="d-flex flex-column flex-lg-center justify-content-center w-lg-50 p-10 order-2 order-lg-1 "> */}
      {/* begin::Form */}

      {/* begin::Wrapper */}

      <div className="w-100" style={{ maxWidth: "36.5rem" }}>
        <Outlet />
      </div>
      {/* end::Wrapper */}

      {/* end::Form */}

      {/* begin::Footer */}
      {/* <div className='d-flex flex-center flex-wrap px-5'>
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div>
        </div> */}
      {/* end::Footer */}
      {/* </div> */}
      {/* end::Body */}

      {/* begin::Aside */}

      {/* end::Aside */}
    </div>
    // </StyledBackground>
  );
};

export { AuthLayout };
