import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import useAuth from "../../auth/hooks/useAuth";
import { Button } from "react-bootstrap";
import { gotoHomePage } from "../../../utils/helpers";

const Error500: FC<{ errorId?: string }> = ({ errorId }) => {

  return (
    <>
      {/* begin::Title */}
      <h1 className="text-danger mb-4" style={{ fontSize: "3.125rem", fontWeight: 100 }}>
        {`We're sorry`}
      </h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6  mb-7">
        An unexpected error has occurred. Our technical team has been automatically notified and will be
        looking into this utmost urgency.
      </div>
      {/* end::Text */}
      <br />
      <br />

      <span className="fw-bolder">Error Id: {errorId || "INTERNAL_SERVER_ERROR_500"}</span>
      {/* begin::Link */}
      <div className="mb-0"></div>
      <div className="d-flex flex-wrap flex-row mt-5">
        <Button className="btn btn-lg btn-primary me-2" onClick={gotoHomePage}>
          Back to Home
        </Button>
      </div>
      {/* end::Link */}
    </>
  );
};

export default Error500;
