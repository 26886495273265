import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { checkIsActive, IIConType, KTIcon, WithChildren } from "../../../helpers";
import { TIConName } from "../../../helpers/icons-config/icons";
import { useIntl } from "react-intl";

type Props = {
  to: string;
  title: string;
  icon?: TIConName;
  iconType?: IIConType;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  iconType = "duotone",
}) => {
  const { pathname } = useLocation();

  const isActive = checkIsActive(pathname, to);
  const { $t } = useIntl();
  return (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion")}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && (
          <span className="menu-icon">
            <KTIcon iconName={icon} iconType={iconType} className="fs-2" />
          </span>
        )}
        {fontIcon && <i className={clsx("bi fs-3", fontIcon)}></i>}
        <span className="menu-title">{$t({ id: title })}</span>
        <span className="menu-arrow"></span>
      </span>
      <div className={clsx("menu-sub menu-sub-accordion", { "menu-active-bg": isActive })}>{children}</div>
    </div>
  );
};

export { AsideMenuItemWithSub };
