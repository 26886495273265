import { useIntl } from "react-intl";

const ProfileCompletion = ({ value, indicatorColor = 'primary' }: { value: number; indicatorColor?: 'success' | 'warning' | 'info' | 'danger' | 'primary' | 'secondary'; }) => {
    const { $t } = useIntl()
    return (
        <div className="d-flex align-items-center flex-column mt-3">
            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                <span className="fw-semibold fs-6 text-primary">{$t({ id: 'MENU.HEADER.ASIDE_PROFILE_COMPLETION' })}</span>
                <span className="fw-bold fs-6">{value}%</span>
            </div>

            <div className="h-5px mx-3 w-100 bg-light mb-3">
                <div
                    className={`bg-${indicatorColor} rounded h-5px`}
                    role="progressbar"
                    style={{ width: `${value}%` }}
                    aria-valuenow={value}
                    aria-valuemin={0}
                    aria-valuemax={100} />
            </div>
        </div>
    )
}

export default ProfileCompletion