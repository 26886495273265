import { useEffect } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { appNotification, selectAppNotification } from "../../app.slice";
import useBreakpoint from "../../hooks/useBreakpoint";
import "./Snackbar.css";
import CustomModal from "../CustomModal/CustomModal";
import { useModal } from "../../hooks/useModal";
import { IIConType, KTIcon } from "../../../_metronic/helpers";
import { TIConName } from "../../../_metronic/helpers/icons-config/icons";
import SweetAlert from "./SweetAlert";
import IconButton from "../IconButton/IconButton";
import AlertBoxLayout from "../AlertBoxLayout/AlertBoxLayout";

const icon: { [x: string]: { color: string; name: TIConName; type: IIConType } } = {
  success: { color: "#50cd89", name: "check-circle", type: "solid" },
  info: { color: "#7239ea", name: "information-2", type: "duotone" },
  danger: { color: "#f1416c", name: "information-5", type: "duotone" },
  error: { color: "red", name: "information", type: "duotone" },
  confirm: { color: "var(--bs-text-warning)", name: "information", type: "duotone" },
  white: { color: "#000000", name: "information-3", type: "duotone" },
};
const ToastWrapper = () => {
  const { title, msg, type = "danger", notificationType } = useSelector(selectAppNotification);
  const { sm } = useBreakpoint();
  const dispatch = useDispatch();
  const { open, onOpen, onClose } = useModal();

  const handleClose = () => {
    dispatch(appNotification({ title: "", msg: undefined }));
    onClose();
  };
  useEffect(() => {
    if (msg && type === "danger") {
      onOpen();
    } else if (msg) {
      onOpen();
      const timer = setTimeout(() => {
        handleClose();
      }, 2500);
      return () => {
        clearTimeout(timer);
        onClose();
      };
    }
  }, [msg]);

  if (notificationType === "modal")
    return (
      <AlertBoxLayout
        open={open}
        onClose={handleClose}
        icon={icon?.[type]}
        type={type}
        msg={msg!}
        title={title!}
      />
    );
  if (sm) return !!msg && <div className="snackbar">{msg}</div>;
  if (notificationType === "alert")
    return (
      <ToastContainer className="p-3" position={"top-end"} style={{ zIndex: 1057, position: "fixed" }}>
        <Toast show={!!msg} style={{ position: "relative", top: 100 }}>
          <Toast.Header className={`bg-light-${type}`}>
            <strong className={`me-auto`}>{title}</strong>
          </Toast.Header>
          <Toast.Body className={`bg-light-${type}`}>
            <span>{msg}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    );

  if (notificationType === "sweetAlert") return !!msg && <SweetAlert />;
  return (
    !!msg && (
      <AlertBoxLayout
        open={open}
        onClose={handleClose}
        icon={icon?.[type]}
        type={type}
        msg={msg!}
        title={title!}
      />
    )
  );
};

export default ToastWrapper;
