/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from "react";
import noUiSlider, { target } from "nouislider";
import { useLayout } from "../../core";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher } from "../../../partials";
import IconButton from "../../../../app/components/IconButton/IconButton";
import { useModal } from "../../../../app/hooks/useModal";
import BootstrapModal from "../../../../app/components/BootstrapModal/BootstrapModal";
import SearchHeader from "../../../../app/components/SearchHeader/SearchHeader";
import { Languages } from "../../../partials/layout/header-menus/Languages";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import useAuth from "../../../../app/modules/auth/hooks/useAuth";
import { Topbar } from "./Topbar";
import { APP_ROUTES } from "../../../../app/routing/routes";
import { capitalizeWords } from "../../../../app/utils/helpers";
import ModeSwitcher from "../../../../app/components/ModeSwitcher/ModeSwitcher";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "w-30px h-30px w-md-40px h-md-40px",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  toolbarButtonIconSizeClass = "fs-1";

const HeaderToolbar = () => {
  const { classes, headerSearchComponentProps } = useLayout();
  const [status, setStatus] = useState<string>("1");
  const { currentUser, currentMode } = useAuth();
  const navigate = useNavigate();
  const handleNavigate = (route: string) => () => {
    navigate(route);
  };
  useEffect(() => {
    const slider: target = document.querySelector("#kt_toolbar_slider") as target;
    const rangeSliderValueElement: Element | null = document.querySelector("#kt_toolbar_slider_value");

    if (!slider) {
      return;
    }

    slider.innerHTML = "";

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    });

    slider.noUiSlider?.on("update", function (values: any, handle: any) {
      if (!rangeSliderValueElement) {
        return;
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1);
    });
  }, []);
  return (
    <div className="toolbar d-flex align-items-stretch">
      {/* begin::Toolbar container */}
      <div
        className={`${classes.headerContainer.join(
          " "
        )} py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between`}
      >
        <DefaultTitle />
        {headerSearchComponentProps && <SearchHeader />}

        <div className="d-flex align-items-stretch overflow-auto pt-3 pt-lg-0">
          {/* begin::Action wrapper */}
          <div className="d-flex align-items-center"></div>
          {/* end::Action wrapper */}

          {/* begin::Action wrapper */}
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="fs-8 fw-bolder me-2">Current mode</span>
              <ModeSwitcher />
            </div>
          </div>
          {/* end::Action wrapper */}

          <div className="d-flex align-items-center ">
            <div className="d-flex gap-2 me-5 align-items-center">
              <div className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}>
                {/* begin::Menu wrapper */}

                <button className="btn btn-icon btn-active-light-primary ">
                  <div className="pulse-icon">
                    <KTIcon iconName="notification-on" className={"fs-1"} />
                    <span className="dot"></span>
                  </div>
                </button>

                {/* end::Menu wrapper */}
              </div>
              {/* <button
                onClick={handleNavigate(APP_ROUTES.MY_PROFILE)}
                className="btn btn-icon btn-active-light-primary "
              >
                <KTIcon iconName="user" className="fs-1" />
              </button> */}
              {/* <div className="d-flex align-items-center">
                <a href='#' className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                  <KTIcon iconName='notification-on' className='fs-1' />
                </a>
              </div> */}

              {/* <div className='d-flex align-items-center'>

                <a href='#' className='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary'>
                  <KTIcon iconName='file-up' className='fs-1' />
                </a>

              </div> */}

              {/* <div className='d-flex align-items-center'>
                <ThemeModeSwitcher toggleBtnClass='btn btn-sm btn-icon btn-icon-muted btn-active-icon-primary' />
              </div> */}
            </div>
            <div className={clsx("app-navbar-item", "d-none d-lg-block")}>
              <div
                className={clsx("btn btn-icon btn-active-light-primary ")}
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                {currentUser?.profile_image ? (
                  <div className="cursor-pointer symbol symbol-25px rounded-circle">
                    <img
                    src={currentUser?.profile_image || toAbsoluteUrl("media/avatars/blank.png")}
                    alt="avatar"
                  />
                  </div>
                ) : (
                  <KTIcon iconName="user" className="fs-2" />
                )}
              </div>
              <HeaderUserMenu />
            </div>
          </div>
          {/* end::Action wrapper */}
        </div>
        {/* end::Toolbar container */}
      </div>
    </div>
  );
};

export { HeaderToolbar };
