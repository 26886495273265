import { FC, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import useAuth from "../../../../app/modules/auth/hooks/useAuth";
import { APP_ROUTES } from "../../../../app/routing/routes";
import { BROADCAST_EVENT, sendTabEvent } from "../../../../app/hooks/useBrowserTabEvent";
import { useIntl } from "react-intl";
import { useDynamicRoutes } from "../../../../app/routing/DynamicRouteProvider";
import ModeSwitcher from "../../../../app/components/ModeSwitcher/ModeSwitcher";
import ProfileCompletion from "../../../../app/components/ProfileCompletion/ProfileCompletion";
import { useProfileCompletion } from "../../../layout/MasterLayout";

const HeaderUserMenu: FC = () => {
  const { $t } = useIntl();
  const { profile_completion_percentage, isLoading: profileLoading } = useProfileCompletion();
  const { currentUser, currentRole, permissions, currentMode } = useAuth();
  const isFirstRender = useRef(true);

  const { isLoading } = useDynamicRoutes();
  const navigate = useNavigate();
  const handleLogout = () => {
    sendTabEvent(BROADCAST_EVENT.LOGOUT_EVENT);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }
    const targetRoute =
      currentMode === "conduct" ? currentUser?.role?.home : currentUser?.role?.participate_home;
    if (targetRoute && !isLoading) {
      navigate(targetRoute);
    }
  }, [isLoading, currentMode]);
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold pb-4 fs-6 w-300px"
      data-kt-menu="true"
      data-popper-placement="bottom-start"
    >
      <div className="menu-item px-3 bg-light-primary pt-0">
        <div className="menu-content d-flex justify-content-center flex-column align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={currentUser?.profile_image || toAbsoluteUrl("media/avatars/blank.png")} />
          </div>

          <div className="d-flex flex-column flex-center">
            <div className="fw-bolder d-flex text-primary align-items-center fs-5">
              {currentUser?.user_name || currentUser?.user_id}
            </div>
            <span className="badge bg-primary text-white">{currentRole}</span>

            <div className="d-flex flex-center flex-wrap gap-2 mt-3">
              <div className="fs-7 d-flex gap-2 align-items-center">
                <i className="bi bi-globe" />
                <span>{currentUser?.company_name}</span>
              </div>
              <div className="fs-7 d-flex gap-2 align-items-center">
                <i className="bi bi-envelope" />
                <span>{currentUser?.email_id}</span>
              </div>
              <div className="fs-7 d-flex gap-2 align-items-center">
                <i className="bi bi-phone" />
                <span>{currentUser?.mobile_number}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="separator mb-2"></div>
      {/* <ModeSwitcher /> */}
      {!profileLoading && profile_completion_percentage !== 100 && (
        <div>
          <div className="px-4">
            <ProfileCompletion value={profile_completion_percentage} />
          </div>
          <div className="separator mb-2"></div>
        </div>
      )}
      {permissions?.["MyProfile"] && (
        <div className="menu-item px-5">
          <Link
            to={currentMode === "conduct" ? APP_ROUTES.MY_PROFILE : APP_ROUTES.PROFILE}
            className="menu-link px-5 gap-2 align-items-start"
          >
            <KTIcon iconName="user" size={16} />
            <div className="d-flex flex-column">{$t({ id: "MENU.HEADER.MY_PROFILE" })}</div>
          </Link>
        </div>
      )}
      {permissions?.["Users"] && (
        <div className="menu-item px-5 ">
          <Link to={APP_ROUTES.USERS} className="menu-link px-5 gap-2 align-items-center">
            <KTIcon iconName="people" size={16} />
            {$t({ id: "MENU.HEADER.MANAGE_USER" })}
          </Link>
        </div>
      )}
      {permissions?.["Settings"] && (
        <div className="menu-item px-5  my-1">
          <Link to={APP_ROUTES.SETTINGS} className="menu-link px-5 gap-2 align-items-center">
            <KTIcon iconName="gear" size={16} />
            {$t({ id: "MENU.HEADER.SETTINGS" })}
          </Link>
        </div>
      )}
      <div className="menu-item px-5  my-1">
        <Link to={APP_ROUTES.HELP} className="menu-link px-5 gap-2 align-items-center">
          <KTIcon iconName="question-2" size={16} />
          {$t({ id: "MENU.HEADER.HELP" })}
        </Link>
      </div>
      <div className="menu-item px-5 ">
        <button onClick={handleLogout} className="fw-bold btn btn-flat w-100 menu-link px-5 ">
          <KTIcon iconName="exit-right" size={16} />
          {$t({ id: "MENU.HEADER.SIGN_OUT" })}
        </button>
        {/* <a onClick={logout} className='menu-link px-5'>
          
        </a> */}
      </div>
    </div>
  );
};

export { HeaderUserMenu };
