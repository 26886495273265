import CustomModal, { CustomModalProps } from "../CustomModal/CustomModal";
import { IIConType, KTIcon } from "../../../_metronic/helpers";
import IconButton from "../IconButton/IconButton";
import { Button } from "react-bootstrap";
import { TIConName } from "../../../_metronic/helpers/icons-config/icons";
import { ReactNode } from "react";
interface IAlertBoxLayout extends Partial<CustomModalProps> {
  open: boolean;
  onClose: () => void;
  icon?: { color: string; name: TIConName; type: IIConType };
  type: string;
  msg: string;
  title: string;
  showIcon?: boolean;
  renderMsg?: (onClose?: () => void) => ReactNode;
  renderButtons?: (onClose?: () => void) => JSX.Element;
  noCloseIcon?: boolean;
}
const AlertBoxLayout = ({
  open,
  onClose,
  icon,
  type,
  msg,
  title,
  renderButtons,
  renderMsg,
  noCloseIcon = false,
  hideOnEscape = true,
  showIcon = true,
  ...rest
}: IAlertBoxLayout) => {
  return (
    <CustomModal
      centered
      open={open}
      handleOnClose={onClose}
      closeModal={onClose}
      footerClassName="border-0 p-4 pt-0 flex-center"
      width="400"
      style={{ zIndex: 1056, borderRadius: "25px", padding: 50 }}
      keyboard={hideOnEscape}
      title={title}
      headerClassName="border-0 p-4 pb-0 align-items-center"
      renderHeader={
        <div className="d-flex justify-content-between align-items-center flex-grow-1">
          <div className="d-flex align-items-center">
            <div className={`symbol rounded p-1 px-2 bg-light-${type} me-3`}>
              {showIcon && (
                <KTIcon
                  iconName={icon?.name as TIConName}
                  iconStyle={{ color: icon?.color }}
                  iconType={icon?.type}
                  size={34}
                />
              )}
            </div>
            <h2 className={`fw-bolder fs-2 fw-bold text-gray-900`}>{title}</h2>
          </div>
          {!noCloseIcon && <IconButton icon={<KTIcon iconName="cross" size={34} />} onClick={onClose} />}
        </div>
      }
      renderFooterButtons={
        renderButtons ? (
          renderButtons(onClose)
        ) : (
          <div className="d-flex flex-center">
            <Button onClick={onClose} variant={type} style={{ borderRadius: "25px" }}>
              Ok
            </Button>
          </div>
        )
      }
      {...rest}
    >
      {renderMsg ? renderMsg(onClose) : <span className="mt-2">{msg || "No information"}</span>}
    </CustomModal>
  );
};

export default AlertBoxLayout;
