export const BASE_URL = `${import.meta.env.VITE_APP_PROD_API_URL}/backend`;

export const BACKEND_BASE_URL = `${import.meta.env.VITE_APP_PROD_BACKEND_BASE_URL}`;
export const USER_MANAGEMENT_BASE_URL = `${
  process.env.NODE_ENV === "development"
    ? import.meta.env.VITE_APP_API_URL
    : import.meta.env.VITE_APP_USER_MANAGEMENT_API_URL
}`;

export const BASE_URL_2 = `https://apis.apnajugaad.co.in/api/backend`;

export const ReportBaseApi: string = `${BASE_URL_2}/reports`;

export const MasterListApi: string = `${BASE_URL}/MasterList`;
export const BackendAuthApi: string = `${BASE_URL}/Auth`;
export const DashBoardRelatedApi: string = `${BASE_URL}/DashBoardRelated`;
export const RegistrationApi: string = `${BASE_URL}/Registration`;
export const VendorProfileApi: string = `${BASE_URL}/VendorProfile`;
export const TelementryApi: string = `${BASE_URL}/Telementry`;
export const TermsAndConditionsApi: string = `${BASE_URL}/MasterTermsAndConditions`;
export const UnitBaseApi: string = `${BASE_URL}/MasterUnit`;
export const DepartmentBaseApi: string = `${BASE_URL}/MasterDepartment`;
export const ProductBaseApi: string = `${BASE_URL}/MasterProduct`;
export const MasterListAuthorizedApi: string = `${BASE_URL}/MasterListAuthorized`;
export const TenderNewApi: string = `${BASE_URL}/RFQ`;
export const ReverseAuctionNewApi: string = `${BASE_URL}/ReverseAuctionNew`;
export const ForwardAuctionNewApi: string = `${BASE_URL}/ForwardAuctionNew`;
export const EquipmentListingNewApi: string = `${BASE_URL}/EquipmentListingNew`;
export const OrganizationApi: string = `${BASE_URL}/MasterOrganization`;
export const UtilityApi: string = `${BASE_URL}/Utility`;
export const SupplierBaseApi: string = `${BASE_URL}/MasterSupplier`;
export const ReverseAuctionApi: string = `${BASE_URL}/ReverseAuction`;
export const ForwardAuctionApi: string = `${BASE_URL}/ForwardAuction`;
export const EventCommonConductModeApi: string = `${BASE_URL}/EventCommonConductMode`;
export const UpcomingEventsApi: string = `${BASE_URL}/UpcomingEvents`;
export const ConductLiveEventApi: string = `${BASE_URL}/ConductLiveEvent`;
export const PlatformRelatedApi: string = `${ReportBaseApi}/platformrelated`;
export const ReverseAuctionReportsApi: string = `${ReportBaseApi}/reverseAuctionReport`;
export const ForwardAuctionReportsApi: string = `${ReportBaseApi}/forwardAuctionReport`;
export const EvaluationReportsApi: string = `${ReportBaseApi}/evaluationReport`;
export const HelpApi: string = `${BASE_URL}/support`;
export const DashboardApi: string = `${BASE_URL}/Dashboard`;
