import { Button } from "react-bootstrap";
import useAuth from "../../modules/auth/hooks/useAuth";
import { IModeType } from "../../pages/users/models/Users.interfaces";
import { MouseEvent } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { selectInitialModeType } from "../../modules/auth/features/auth.slice";
import { useSelector } from "react-redux";

const ModeSwitcher = () => {
  const { currentMode, changeModeType } = useAuth();
  const initial_mode_type = useSelector(selectInitialModeType);

  const { $t } = useIntl();
  const handleChangeMode = (mode: IModeType) => (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeModeType({ mode });
  };
  return initial_mode_type === "conduct,participate" ? (
    <div className="d-flex p-5 br-4 btn-group">
      {["conduct", "participate"].map((mode, index) => {
        const isSelected = currentMode === mode;
        return (
          <Button
            key={mode}
            size="sm"
            variant={currentMode === mode ? "primary" : "outline"}
            style={{
              borderBottomLeftRadius: index === 0 ? 40 : 0,
              borderTopLeftRadius: index === 0 ? 40 : 0,
              borderBottomRightRadius: index === 1 ? 40 : 0,
              borderTopRightRadius: index === 1 ? 40 : 0,
            }}
            onClick={handleChangeMode(mode as IModeType)}
          >
            <div className="d-flex gap-2 align-items-center">
              <SVG
                src={toAbsoluteUrl(`media/misc/${mode}-mode.svg`)}
                width={16}
                height={16}
                title={mode as string}
                className={clsx(!isSelected && "d-block", isSelected && "d-none")}
              />
              <SVG
                src={toAbsoluteUrl(`media/misc/${mode}-mode-dark.svg`)}
                width={16}
                height={16}
                title={mode as string}
                className={clsx(isSelected && "d-block", !isSelected && "d-none")}
              />
              {mode === "conduct"
                ? $t({ id: "MENU.HEADER.CONDUCT_MODE" })
                : $t({ id: "MENU.HEADER.PARTICIPATE_MODE" })}
            </div>
          </Button>
        );
      })}
    </div>
  ) : null;
};

export default ModeSwitcher;
