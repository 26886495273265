import clsx from "clsx";
import { ForwardedRef, InputHTMLAttributes, MouseEvent, forwardRef, useState } from "react";
import IconButton from "../IconButton/IconButton";
import FormInputLayout, { IFormInputProps } from "../FormBuilder/FormInputLayout/FormInputLayout";

export type TInputProps = {
  error?: string;
  touched?: boolean;
  inputType?: "solid" | "outline";
} & InputHTMLAttributes<HTMLInputElement> &
  IFormInputProps;

const PasswordInput = forwardRef(
  (
    { touched, error, inputType = "outline", name, label, left, labelProps, ...props }: TInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [show, setShow] = useState(false);
    const togglePasswordVisibility = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      setShow(!show);
    };

    return (
      <FormInputLayout
        inputType={inputType}
        error={error}
        name={name}
        label={label}
        floating={true}
        left={left}
        labelProps={labelProps}
        right={
          <span className="bg-white d-flex flex-center px-3 py-1 input-group-text">
            <IconButton icon="eye" type="button" iconSize={18} onClick={togglePasswordVisibility} />
          </span>
        }
      >
        <input
          ref={ref}
          {...props}
          type={show ? "text" : "password"}
          autoComplete="off"
          className={clsx(
            `form-control  ${props?.className || `form-control-${inputType || "transparent"}`}`,
            { "is-invalid": touched && error },
            {
              "is-valid": touched && !error,
            }
          )}
        />
      </FormInputLayout>
    );

    return (
      <div className={`input-group input-group-${inputType}`}>
        <input
          ref={ref}
          {...props}
          type={show ? "text" : "password"}
          autoComplete="off"
          className={clsx(
            `form-control  px-3 py-1 ${props?.className || `form-control-${inputType || "transparent"}`}`,
            { "is-invalid": touched && error },
            {
              "is-valid": touched && !error,
            }
          )}
        />
        <span className="input-group-text px-3 py-1">
          <IconButton icon="eye" type="button" iconSize={18} onClick={togglePasswordVisibility} />
        </span>
      </div>
    );
  }
);

export default PasswordInput;
