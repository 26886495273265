export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);

  if (!current || !url) {
    return false;
  }

  // Convert paths into arrays for structure checking
  const currentSegments = current.split("/").filter(Boolean);
  const urlSegments = url.split("/").filter(Boolean);

  // Parent-child relationship check: `url` should be a parent or exact match
  if (
    current.startsWith(`/${url}`) &&
    (currentSegments.length === urlSegments.length || currentSegments.length > urlSegments.length)
  ) {
    // Ensure it's within the same main section (e.g., /participate vs /participate-reports)
    if (currentSegments[0] === urlSegments[0]) {
      return true;
    }
  }

  return false;
}
