/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FC,
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Component,
  ComponentType,
} from "react";
import { DefaultLayoutConfig } from "./DefaultLayoutConfig";
import { getEmptyCssClasses, getEmptyCSSVariables, getEmptyHTMLAttributes, LayoutSetup } from "./LayoutSetup";
import { ILayout, ILayoutCSSVariables, ILayoutCSSClasses, ILayoutHTMLAttributes } from "./LayoutModels";
import { WithChildren } from "../../helpers";
import { Props } from "react-select";
import { AsyncProps } from "react-select/async";
export interface LayoutContextModel {
  config: ILayout;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
  setLayout: (config: LayoutSetup) => void;
  enableSplashScreen: () => void;
  disableSplashScreen: () => void;
  setHeaderSearchComponentProps?: React.Dispatch<
    (Props & { type: "select" }) | (AsyncProps<any, any, any> & { type: "asyncSelect" }) | undefined
  >;
  headerSearchComponentProps?:
    | (Props & { type: "select" })
    | (AsyncProps<any, any, any> & { type: "asyncSelect" });
}
const enableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    const closestSplashScreen = splashScreen.closest(".splash-screen");
    if (closestSplashScreen) {
      (closestSplashScreen as HTMLElement).style.setProperty("display", "flex");
    }
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    const closestSplashScreen = splashScreen.closest(".splash-screen");
    if (closestSplashScreen) {
      (closestSplashScreen as HTMLElement).style.setProperty("display", "none");
    }
    document.body.classList.remove("page-loading");
  }
};
const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  enableSplashScreen,
  disableSplashScreen,
});

const LayoutProvider: FC<WithChildren> = ({ children }) => {
  const [config, setConfig] = useState(LayoutSetup.config);
  const [classes, setClasses] = useState(LayoutSetup.classes);
  const [attributes, setAttributes] = useState(LayoutSetup.attributes);
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);
  const [headerSearchComponentProps, setHeaderSearchComponentProps] = useState<any>();
  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen();
    const bodyClasses = Array.from(document.body.classList);
    bodyClasses.forEach((cl) => document.body.classList.remove(cl));
    LayoutSetup.updatePartialConfig(_themeConfig);
    setConfig(Object.assign({}, LayoutSetup.config));
    setClasses(LayoutSetup.classes);
    setAttributes(LayoutSetup.attributes);
    setCSSVariables(LayoutSetup.cssVariables);
    setTimeout(() => {
      disableSplashScreen();
    }, 1500);
  };

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    enableSplashScreen,
    disableSplashScreen,
    setHeaderSearchComponentProps,
    headerSearchComponentProps,
  };

  useEffect(() => {
    disableSplashScreen();
  }, []);

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
  return useContext(LayoutContext);
}
