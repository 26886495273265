import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import {
  AppState,
  IAppNotificationPayload,
  ILocalNotification,
  ISidebarCounterMap,
  IUploadFileState,
} from "./app.interfaces";
import { appApi } from "./app.api";
import { IModeType } from "./pages/users/models/Users.interfaces";

const INITIAL_STATE: AppState = {
  appNotification: {
    title: "",
    msg: undefined,
    type: "white",
    notificationType: "toast",
  },
  uploadFile: {
    progress: 0,
    status: "idle",
  },
  reload: "",
  localNotification: [],
  sidebarCounter: undefined,
};
const slice = createSlice({
  name: "app",
  initialState: INITIAL_STATE,
  reducers: {
    appNotification: (state: AppState, { payload }: { payload: IAppNotificationPayload }) => {
      state.appNotification = { ...payload };
    },
    localNotification: (state: AppState, { payload }: { payload: ILocalNotification }) => {
      state.localNotification = [{ ...payload }, ...state.localNotification];
    },
    updateSidebarCounter: (state: AppState, { payload }: { payload: ISidebarCounterMap }) => {
      state.sidebarCounter = { ...payload };
    },
    reloadPage: (state: AppState, { payload }: { payload: string }) => {
      state.reload = payload;
    },
    setProgress: (state, { payload }: { payload: IUploadFileState }) => {
      state.uploadFile = payload;
    },
  },
});

export const { appNotification, localNotification, updateSidebarCounter, reloadPage, setProgress } =
  slice.actions;
export const selectAppNotification = (state: RootState) => state.app.appNotification;
export const selectSidebarCounter = (state: RootState) => state.app.sidebarCounter;
export const selectLocalNotification = (state: RootState) => state.app.localNotification;
export const selectReloadPage = (state: RootState) => state.app.reload;
export const selectUploadFileProgress = (state: RootState) => state.app.uploadFile;
export const unReadLocalNotificationCount = (state: RootState) =>
  state.app.localNotification.map((v) => !v.isRead);

export default slice.reducer;
