import { Button, ButtonProps, Spinner } from "react-bootstrap";
interface ILoadingButton extends ButtonProps {
  loading?: boolean;
  variant?: string;
}
const LoadingButton = ({ loading, children, disabled, variant = "primary", ...rest }: ILoadingButton) => {
  return (
    <Button variant={variant} className="z-index-0" disabled={disabled || loading} {...rest}>
      {loading ? <Spinner animation="border" size="sm" /> : children}
    </Button>
  );
};

export default LoadingButton;
