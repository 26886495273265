import { Controller, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useSelector } from "react-redux";
import formValidationRules from "../../utils/formValidationRules";
import { selectCurrentUser } from "../../modules/auth/features/auth.slice";
import PasswordInput from "../PasswordInput/PasswordInput";
import FieldErrorText from "../FormBuilder/FieldErrorText";
import { useChangePasswordMutation } from "../../pages/users/services/users.api";
import LoadingButton from "../LoadingButton/LoadingButton";

interface IChangePasswordForm {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

const schema = Joi.object({
  current_password: formValidationRules.password.label("Current Password"),
  new_password: formValidationRules.password.label("New Password"),
  confirm_password: formValidationRules.confirmPassword.label("Confirm Password"),
});

const ChangePassword = ({
  onClose,
  afterSubmit,
  unique_id,
}: {
  onClose?: () => void;
  afterSubmit?: () => void;
  unique_id?: string;
}) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: joiResolver(schema),
  });
  const [changePasswordSubmit, { isLoading }] = useChangePasswordMutation();
  const currentUser = useSelector(selectCurrentUser);
  const onSubmit = (data: IChangePasswordForm) => {
    changePasswordSubmit({ ...data, unique_id: unique_id || currentUser?.unique_id }).finally(() => {
      if (onClose) onClose();
      if (afterSubmit) afterSubmit();
    });
  };

  return (
    <form>
      <div className="d-flex flex-column">
        <div className="mb-7">
          <div className="mb-3">
            <Controller
              name="current_password"
              control={control}
              render={({ field }) => (
                <PasswordInput {...field} label="Current Password" placeholder="Current Password" />
              )}
            />
            {errors.current_password?.message && <FieldErrorText error={errors.current_password?.message} />}
          </div>
          <div className="mb-3">
            <Controller
              name="new_password"
              control={control}
              render={({ field }) => (
                <PasswordInput {...field} label="New Password" placeholder="New Password" />
              )}
            />

            {errors.new_password?.message && <FieldErrorText error={errors.new_password?.message} />}
          </div>
          <div className="mb-3">
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <PasswordInput {...field} label="Confirm Password" placeholder="Confirm Password" />
              )}
            />
            {errors.confirm_password?.message && <FieldErrorText error={errors.confirm_password?.message} />}
          </div>
        </div>
      </div>
      <br />
      <div className="d-flex justify-content-end">
        {onClose && (
          <button className="btn btn-flat" onClick={onClose}>
            Cancel
          </button>
        )}
        <LoadingButton
          className="btn btn-primary"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Change Password
        </LoadingButton>
      </div>
    </form>
  );
};

export default ChangePassword;
