import { HTMLAttributes, ReactNode } from "react";
import FieldErrorText from "../FieldErrorText";

export interface IFormInputProps {
  inputType?: "outline" | "solid";
  left?: ReactNode;
  right?: ReactNode;
  label?: string;
  id?: string;
  name: string;
  error?: any;
  labelProps?: HTMLAttributes<HTMLLabelElement>;
  showRequiredStar?: boolean;
  floating?: boolean;
}
export interface IFormInputLayout extends IFormInputProps {
  children?: any;
}
const FormInputLayout = (field: IFormInputLayout) => {
  if (field?.floating)
    return (
      <div>
        <div className={`input-group input-group-${field?.inputType || "outline"}`}>
          {typeof field?.left === "string" ? (
            <span className="input-group-text px-3 py-1">{field?.left}</span>
          ) : (
            field?.left
          )}
          <div className="form-floating">
            {field?.children}
            {field?.label && (
              <label
                htmlFor={field?.id || field?.name}
                {...field?.labelProps}
                className={`${field?.labelProps?.className || "text-gray-700 fs-6 mb-2"} ${
                  field?.showRequiredStar ? " required" : ""
                }`}
              >
                {field?.label}
              </label>
            )}
          </div>
          {typeof field?.right === "string" ? (
            <span className="input-group-text px-3 py-1">{field?.right}</span>
          ) : (
            field?.right
          )}
        </div>
        {field?.error && <FieldErrorText error={field?.error?.message} />}
      </div>
    );
  return (
    <div>
      {field?.label && (
        <label
          htmlFor={field?.id || field?.name}
          className={`${field?.labelProps?.className || "text-gray-700 fs-6 mb-2"} ${
            field?.showRequiredStar ? "required" : ""
          }`}
        >
          {field?.label}
        </label>
      )}
      <div className={`input-group input-group-${field?.inputType || "outline"}`}>
        {typeof field?.left === "string" ? (
          <span className="input-group-text px-3 py-1">{field?.left}</span>
        ) : (
          field?.left
        )}

        {field?.children}
        {typeof field?.right === "string" ? (
          <span className="input-group-text px-3 py-1">{field?.right}</span>
        ) : (
          field?.right
        )}
      </div>
      {field?.error && <FieldErrorText error={field?.error?.message} />}
    </div>
  );
};

export default FormInputLayout;
