import { ISidebarCounter } from "./app.interfaces";
import { appNotification } from "./app.slice";
import { deAuthUser, logoutUser } from "./modules/auth/features/auth.slice";
import { IRolesAndPersmissions } from "./modules/auth/models/Auth.interface";
import { IResponse } from "./network/network.interfaces";
import { IModeType } from "./pages/users/models/Users.interfaces";
import { api } from "./services/api/api";
import {
  BASE_URL,
  DashboardApi,
  DashBoardRelatedApi,
  TelementryApi,
  USER_MANAGEMENT_BASE_URL,
  VendorProfileApi,
} from "./services/api/api.constant";

export const appApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRolesAndPermissions: builder.query<IResponse<IRolesAndPersmissions>, { reload?: string }>({
      query: (payload) => ({
        url: `${USER_MANAGEMENT_BASE_URL}/modules/getModules`,
        method: "GET",
        params: payload,
      }),
      providesTags: () => [{ type: "Modules", id: "PERMISSIONS" }],
    }),
    checkApproval: builder.mutation<IResponse<any>, void>({
      query: () => ({
        url: `${VendorProfileApi}/checkApproval`,
        method: "POST",
        body: {},
      }),
    }),
    getSideBarCounter: builder.query<IResponse<ISidebarCounter[]>, void>({
      query: () => ({
        url: `${DashBoardRelatedApi}/leftNavigationCounters`,
        method: "POST",
        body: {},
      }),
      providesTags: () => [{ type: "SidebarCounter", id: "LIST" }],
    }),
    getheartBeat: builder.query<IResponse<{ message: string; status: boolean }>, void>({
      query: () => ({
        url: `${TelementryApi}/heartBeat`,
        method: "POST",
        body: {},
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result: any = await queryFulfilled;

          if (result.meta?.response?.status !== 200) {
            dispatch(
              appNotification({
                title: "Error",
                notificationType: "alert",
                msg: result?.data?.message || "Access denied!",
              })
            );
            dispatch(deAuthUser(null));
            // dispatch(logoutUser());
            // window.location.pathname = "/logout";
          }
        } catch (err) {
          dispatch(deAuthUser(null));
          dispatch(appNotification({ title: "Error", msg: "something went wrong!" }));
        }
      },
    }),
    getVersion: builder.query<{ version: string }, void>({
      query: () => ({
        url: `${BASE_URL}/APIBackedVersion/getVersion`,
        method: "POST",
        body: {},
      }),
    }),
    getProfileCompletion: builder.query<any, void>({
      query: () => ({
        url: `${DashboardApi}/getProfileScore`,
        method: "POST",
        body: {},
      }),
    }),
  }),
});

export const {
  useGetRolesAndPermissionsQuery,
  useLazyGetRolesAndPermissionsQuery,
  useGetSideBarCounterQuery,
  useLazyGetSideBarCounterQuery,
  useCheckApprovalMutation,
  useGetheartBeatQuery,
  useLazyGetheartBeatQuery,
  useGetVersionQuery,
  useLazyGetVersionQuery,
  useGetProfileCompletionQuery,
  useLazyGetProfileCompletionQuery,
} = appApi;
